import React from 'react'
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader'
import { format } from 'date-fns'

import Page from '../layouts/page'

import Title from '../components/layout/title'
import { Helmet } from 'react-helmet'

const Post = ({ pageContext: { post, ...context }, ...props }) => {
  deckDeckGoHighlightElement()

  return (
    <Page>
      <Helmet>
        <title>{post.frontmatter.title} | Nathan Dunn - Software Developer, Consultant and Speaker</title>
        <meta name="description" content={post.frontmatter.summary} />
        <meta property="og:title" content={post.frontmatter.title} />
        <meta property="og:description" content={post.frontmatter.summary} />
      </Helmet>

      <article itemProp="mainEntity" itemScope itemType="http://schema.org/BlogPosting">
        <Title itemProp="headline">{post.frontmatter.title}</Title>

        <p className="text-olive text-xl text-opacity-75 font-semibold">
          <span>Posted</span> <time dateTime={post.frontmatter.date} itemProp="datePublished">{format(new Date(post.frontmatter.date), 'do MMMM yyyy')}</time>
        </p>

        <div className="mt-4" itemProp="articleBody">
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </div>
      </article>
    </Page>
  )
}

export default Post